// intersectionObserver
const footer = document.querySelector(".l-footer");
const cv = document.querySelector(".c-button__cv");

//オプション設定
const options = {
  root: null,
  rootMargin: "-10%",
  threshold: 0,
};

// 第一引数にはフェードインした時に発火させる関数、第二引数にはオプションを記載
const observer = new IntersectionObserver(fadein, options);

// 監視対象にする
observer.observe(footer);

function fadein(entries) {
  // entriesにIntersectionObserverからもらった監視対象を入れて
  entries.forEach((entry) => {
    // 監視対象の要素が交差しているかどうかを判定
    if (entry.isIntersecting) {
      cv.classList.add("js-active");
    } else {
      cv.classList.remove("js-active");
    }
  });
}
