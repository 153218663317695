// Swiper
import Swiper, { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css";
Swiper.use([Navigation, Pagination, Autoplay]);

// swiper
window.addEventListener("DOMContentLoaded", () => {
  const slider = new Swiper(".p-top__slider", {
    loop: true,
    loopedSlides: 5,
    slidesPerView: "auto",
    speed: 8000,
    autoplay: {
      delay: 0,
    },
  });
});
